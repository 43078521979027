import React from "react";
import { useState } from "react";
import "./calculator.css";
import { ImCalculator } from "react-icons/im";
import solarImage from "../images/sunny.gif";

import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

const Calculator = () => {
  const [roofTop, setRoofTop] = useState();
  const [consumption, setConsumption] = useState();
  const [bill, setBill] = useState();
  const [total, setTotal] = useState();

  function handleClickRoof() {
    setTotal(Math.ceil(roofTop / 10));
  }
  function handleClickCon() {
    setTotal(Math.ceil((consumption * 12) / 1450));
  }
  function handleClickBill() {
    setTotal(Math.ceil(bill / 1000));
  }

  useEffect(() => {
    Aos.init({ duration: 900 });
  }, []);

  return (
    <section id="calculator">
      <div className="calculator__heading">
        <h2 className="calc__icon">
          {" "}
          <ImCalculator />{" "}
        </h2>
        <h2 className="calc__heading">
          Calculate Total Installation Need In kW
        </h2>
      </div>
      <div className="container calculator__container">
        <div className="rooftop__container" data-aos="fade-right">
          <label>Enter Rooftop Area in sq meter</label>
          <input
            type="text"
            name="roofTop"
            placeholder="roof-top area or ground area in sq meter"
            onChange={(event) => {
              setRoofTop(event.target.value);
            }}
          />
          <button onClick={handleClickRoof} className="btn btn-lg">
            Calculate
          </button>
        </div>
        <div className="consumption__container" data-aos="fade-right">
          <label>Enter consumption</label>
          <input
            type="text"
            name="roofTop"
            placeholder="Monthly consumption in unit"
            onChange={(event) => {
              setConsumption(event.target.value);
            }}
          />
          <button onClick={handleClickCon} className="btn btn-lg">
            calculate
          </button>
        </div>
        <div className="bill__container" data-aos="fade-right">
          <label>Enter bill</label>
          <input
            type="text"
            name="roofTop"
            placeholder="Electricity Bill"
            onChange={(event) => {
              setBill(event.target.value);
            }}
          />
          <button onClick={handleClickBill} className="btn btn-lg">
            calculate
          </button>
        </div>
        <li className="result__container">
          {total !== undefined && total > 0
            ? "Needed installation of : " + total + " kW"
            : total === undefined
            ? ""
            : "Enter Input in Numbers only"}
        </li>
        {/* <div className="icon__container">
          <img src={solarImage} alt="" />
        </div> */}
      </div>
    </section>
  );
};

export default Calculator;
