import React from "react";
import HeaderImage from "../../images/solar-header-op.jpg";
import Header from "../../components/Header";
import ManufacturingImg from "../../images/manufacturing.png";
import { MdDeveloperBoard } from "react-icons/md";
import { AiOutlineAim } from "react-icons/ai";
import { FcSalesPerformance } from "react-icons/fc";
import { Link } from "react-router-dom";

const Manufacturing = () => {
  return (
    <>
      <Header title="Solar PV Manufacturing" image={HeaderImage}>
        GREENPOWER SOLUTIONS is India’s leading solar PV module manufacturer,
        with a total installed module manufacturing capacity of 15MW.
      </Header>
      <section>
        <div className="updated__vision-container">
          <p
            style={{
              fontSize: "1.1rem",
            }}
          >
            Located at Kolkata, India, our world-class PV module (also known as
            solar panel) manufacturing division is home to state of the art
            semi-automated European line, PV Module Testing Lab and extensive
            R&D resources. The GPS SOLAR range of PV Modules comprises of
            reliable, quality modules, that are ‘Made In India’ and shipped
            worldwide. <br /> They have been designed to suit applications from
            small retail lighting systems, to specialised applications like
            pumping and utility-scale solar power plants. 
          </p>
        </div>

        <div className="updated__vision-container">
          <h4>
            <span>Why</span> GPS SOLAR
          </h4>
          <img
            style={{
              marginTop: "1rem",
              width: "550px",
              height: "400px",
            }}
            src={ManufacturingImg}
            alt=""
          />
        </div>
        <div className="updated__vision-container">
          <h4>Salient features</h4>
          <h4>
            <span>
              <MdDeveloperBoard />
            </span>{" "}
            {""}
            <span>SAFETY</span>
          </h4>
          <h5
            style={{
              fontSize: "2rem",
              color: "grey",
            }}
          >
            IP67 Junction Box
          </h5>
          <h5
            style={{
              fontSize: "2rem",
              color: "grey",
            }}
          >
            12 Year Product Warranty
          </h5>
          <h5
            style={{
              fontSize: "2rem",
              color: "grey",
            }}
          >
            27 Year of Limited Power Output Warranty
          </h5>
          <h5
            style={{
              fontSize: "2rem",
              color: "grey",
            }}
          >
            27 Year of Limited Power Output Warranty
          </h5>
        </div>
        <div className="updated__vision-container">
          <h4>Reliability</h4>
          <h4>
            <span>
              <AiOutlineAim />
            </span>{" "}
            {""}
            <span>Extreme Weather Resilience</span>
          </h4>
          <h5
            style={{
              fontSize: "2rem",
              color: "grey",
            }}
          >
            Windload - 2400 Pa
          </h5>
          <h5
            style={{
              fontSize: "2rem",
              color: "grey",
            }}
          >
            Snowload - 5400 Pa{" "}
          </h5>
          <h5
            style={{
              fontSize: "2rem",
              color: "grey",
            }}
          >
            Highly Reliable Anti-Reflective Coated Glass{" "}
          </h5>
        </div>
        <div className="updated__vision-container">
          <h4>
            <span>
              <FcSalesPerformance />
            </span>{" "}
            <span>PERFORMANCE</span>
          </h4>
          <h5
            style={{
              fontSize: "2rem",
              color: "grey",
            }}
          >
            PID Resistant{" "}
          </h5>
          <h5
            style={{
              fontSize: "2rem",
              color: "grey",
            }}
          >
            Low Light Performance{" "}
          </h5>
          <h5
            style={{
              fontSize: "2rem",
              color: "grey",
            }}
          >
            High Power Density{" "}
          </h5>
          <h5
            style={{
              fontSize: "2rem",
              color: "grey",
            }}
          >
            Positive Power Tolerance{" "}
          </h5>
        </div>
        <div className="updated__vision-container">
          <h4
            style={{
              fontSize: "2.4rem",
            }}
          >
            <span>
              Why Choose Green Power Solutions for Your Solar PV Modules?{" "}
            </span>
          </h4>
          <ul>
            <li
              style={{
                fontSize: "1.1rem",
                marginBottom: "1rem",
              }}
            >
              <span
                style={{
                  fontWeight: "900",
                }}
              >
                {" "}
                1. Expertise in Solar Technology :{" "}
              </span>{" "}
              With a wealth of experience in the solar industry, Green Power
              Solutions boasts a team of seasoned professionals dedicated to
              pushing the boundaries of solar technology. We stay at the
              forefront of PV module advancements to provide our customers with
              top-tier solutions.
            </li>
            <li
              style={{
                fontSize: "1.1rem",
                marginBottom: "1rem",
              }}
            >
              <span
                style={{
                  fontWeight: "900",
                }}
              >
                {" "}
                2. Cutting-Edge Products :{" "}
              </span>{" "}
              We offer a wide range of Solar PV Modules designed to cater to
              various applications. Whether you need modules for residential,
              commercial, or industrial purposes, we provide high-quality,
              efficient products that meet your energy needs.
            </li>
            <li
              style={{
                fontSize: "1.1rem",
                marginBottom: "1rem",
              }}
            >
              <span
                style={{
                  fontWeight: "900",
                }}
              >
                {" "}
                3. Commitment to Sustainability :{" "}
              </span>{" "}
              We are passionate about promoting environmental sustainability.
              Our Solar PV Modules capture clean, renewable solar energy,
              reducing dependence on non-renewable sources and contributing to a
              greener planet.
            </li>
            <li
              style={{
                fontSize: "1.1rem",
                marginBottom: "1rem",
              }}
            >
              <span
                style={{
                  fontWeight: "900",
                }}
              >
                {" "}
                4. Cost-Effective Energy :{" "}
              </span>{" "}
              Green Power Solutions' Solar PV Modules offer a cost-effective way
              to generate electricity. By converting sunlight into power, our
              modules help you lower your energy bills while reducing your
              carbon footprint.
            </li>
            <li
              style={{
                fontSize: "1.1rem",
                marginBottom: "1rem",
              }}
            >
              <span
                style={{
                  fontWeight: "900",
                }}
              >
                {" "}
                5. Robust and Durable :{" "}
              </span>{" "}
              We understand that Solar PV Modules must withstand harsh
              environmental conditions. Our products are built to last, ensuring
              long-term performance and minimal maintenance requirements.
            </li>
            <li
              style={{
                fontSize: "1.1rem",
                marginBottom: "1rem",
              }}
            >
              <span
                style={{
                  fontWeight: "900",
                }}
              >
                {" "}
                6. Customization Options :{" "}
              </span>{" "}
              Your energy needs are unique, and we recognize that. Our Solar PV
              Modules can be customized to meet your specific requirements,
              whether it's the module size, output, or design.
            </li>
          </ul>
          <p
            style={{
              marginTop: "1rem",
              fontSize: "1.1rem",
            }}
          >
            Green Power Solutions is committed to providing the world with
            cleaner, greener, and more energy-efficient solutions. We believe
            that Solar PV Modules are not just a technology; they are a pathway
            to a more sustainable and eco-friendly future. By choosing our
            products, you join us in the mission to transform the energy
            landscape. <br />
            Whether you're a homeowner looking to reduce your energy costs, a
            business owner aiming to go green, or a developer seeking
            sustainable energy solutions, Green Power Solutions is your trusted
            partner for high-quality Solar PV Modules. Empower your world with
            the sun - choose Green Power Solutions and be a part of the
            renewable energy revolution.
          </p>
        </div>
      </section>
      <div className="programs__button">
        <Link to="/contact" className=" btn ">
          Contact Us
        </Link>
      </div>
    </>
  );
};

export default Manufacturing;
