import React from "react";
import HeaderImage from "../../images/solar-header-op.jpg";
import Header from "../../components/Header";
import CreditsAccordian from "../../components/CreditsAccordian";
import { Link } from "react-router-dom";

const CarbonCredits = () => {
  return (
    <>
      <Header title="Carbon Credits" image={HeaderImage}>
        Registration, Validation and Trade of Carbon Credits: Understanding the
        Basics. Get a better understanding of the process and how it helps in
        reducing greenhouse gas emissions.
      </Header>

      <section>
        <div className="updated__vision-container">
          <h4>Introduction</h4>
          <p>
            Climate change is a global issue that requires immediate action. One
            of the ways to mitigate climate change is by reducing greenhouse gas
            emissions. The registration, validation, and trade of carbon credits
            play a crucial role in reducing greenhouse gas emissions. Carbon
            credits are a way to measure and reduce greenhouse gas emissions by
            companies and organizations. In this article, we will dive deep into
            the registration, validation, and trade of carbon credits. We will
            discuss the basics, the process, and its importance in mitigating
            climate change.
          </p>
        </div>

        <div className="updated__vision-container">
          <h4>
            What are <span>Carbon Credits ? </span>
          </h4>
          <p>
            Carbon credits are a way to measure and reduce greenhouse gas
            emissions. A carbon credit represents one ton of carbon dioxide or
            its equivalent in other greenhouse gases. Companies or organizations
            can earn carbon credits by reducing their greenhouse gas emissions
            below a certain level. These credits can then be traded or sold to
            companies or organizations that have exceeded their greenhouse gas
            emission limits.
          </p>
        </div>
        <div className="updated__vision-container">
          <h4>Registration, Validation and Trade of Carbon Credits</h4>
          <p>
            The process of registration, validation, and trade of carbon credits
            is a complex process that involves multiple steps.
          </p>
          <h4
            style={{
              fontSize: "1.6rem",
              marginTop: "0.9rem",
            }}
          >
            <span>Registration</span>
          </h4>
          <p>
            The first step in the process is registration. Companies or
            organizations that want to earn carbon credits must register with a
            carbon credit standard. There are several carbon credit standards
            available, and each standard has its own criteria for registration.
            The registration process involves submitting a project design
            document (PDD) to the carbon credit standard. The PDD contains
            detailed information about the project, including the emissions
            reduction potential and the methodology used to calculate the
            emissions reduction.
          </p>

          <h4
            style={{
              fontSize: "1.6rem",
              marginTop: "0.9rem",
            }}
          >
            <span>Validation</span>
          </h4>
          <p>
            After the registration process is complete, the next step is
            validation. The validation process involves an independent
            third-party auditor who reviews the PDD and verifies the information
            provided. The auditor ensures that the project meets the criteria
            set by the carbon credit standard. Once the project is validated,
            the carbon credits can be issued. The carbon credits are issued in
            the form of verified carbon units (VCUs).
          </p>
          <h4
            style={{
              fontSize: "1.6rem",
              marginTop: "0.9rem",
            }}
          >
            <span>Trade</span>
          </h4>
          <p>
            Once the carbon credits are issued, they can be traded or sold to
            companies or organizations that have exceeded their greenhouse gas
            emission limits. The price of carbon credits is determined by supply
            and demand. As the demand for carbon credits increases, the price
            increases.
          </p>
        </div>
        <div className="updated__vision-container">
          <h4>
            <span>Importance of</span> Carbon Credits
          </h4>
          <p
            style={{
              marginTop: "1rem",
            }}
          >
            Carbon credits play a vital role in reducing greenhouse gas
            emissions. They provide an incentive for companies and organizations
            to reduce their greenhouse gas emissions. The sale and purchase of
            carbon credits create a market-based mechanism to reduce greenhouse
            gas emissions.
          </p>
          <p
            style={{
              marginTop: "1rem",
            }}
          >
            The revenue generated from the sale of carbon credits can also be
            used to fund further emissions reduction projects. This creates a
            cycle of continuous emissions reductions and revenue generation.
          </p>
          <p
            style={{
              marginTop: "1rem",
            }}
          >
            The registration, validation, and trade of carbon credits play a
            crucial role in reducing greenhouse gas emissions. Carbon credits
            provide an incentive for companies and organizations to reduce their
            greenhouse gas emissions and create a market-based mechanism to
            mitigate climate change. However, ensuring the integrity of the
            process is crucial to maintain the credibility of the carbon credit
            market.
          </p>
          <p
            style={{
              marginTop: "1rem",
            }}
          >
            As the world continues to grapple with the effects of climate
            change, it is essential to take concrete steps to mitigate its
            impact. The registration, validation, and trade of carbon credits
            provide a way to do just that.
          </p>
        </div>

        <div
          style={{
            marginBottom: "1rem",
          }}
          className="updated__vision-container"
        >
          <h4>
            <span>FAQs</span> About Carbon Credits
          </h4>
        </div>
        <div
          style={{
            marginRight: "6rem",
            marginLeft: "6rem",
          }}
        >
          <CreditsAccordian />
        </div>
      </section>
      <div className="programs__button">
        <Link to="/contact" className=" btn ">
          Contact Us
        </Link>
      </div>
    </>
  );
};

export default CarbonCredits;
