import React from "react";
import HeaderImage from "../../images/solar-header-op.jpg";
import Header from "../../components/Header";
import { GiDoubleStreetLights } from "react-icons/gi";
import { Link } from "react-router-dom";

const StreetLight = () => {
  return (
    <>
      <Header
        title="Illuminate the Future with Green Power Solutions - Your Expert in Solar Street Lights"
        image={HeaderImage}
      >
        Welcome to Green Power Solutions, where innovation meets sustainability
        to illuminate a brighter, cleaner future. We are your trusted experts in
        the manufacturing of solar street lights, offering a wide range of
        solutions designed to light up the streets with renewable energy.
      </Header>
      <section>
        <div className="updated__vision-container">
          <p style={{ fontSize: "1.1rem" }}>
            At Green Power Solutions, we understand the importance of
            sustainable lighting solutions in today's world. With the growing
            concern for environmental conservation and the need for energy
            efficiency, solar street lights have emerged as a beacon of hope.
            They not only reduce carbon footprints but also lower energy costs,
            making them an ideal choice for a cleaner and greener tomorrow.
          </p>
        </div>
        <div className="updated__vision-container">
          <h4 style={{ fontSize: "2.3rem" }}>
            <span>
              <GiDoubleStreetLights />
            </span>{" "}
            <span>
              Why Choose Green Power Solutions for Your Solar Street Lights?
            </span>
          </h4>
          <ul>
            <li
              style={{
                fontSize: "1.1rem",
                marginBottom: "1rem",
              }}
            >
              <span style={{ fontWeight: "900" }}>
                {" "}
                1. Expertise in Solar Technology :
              </span>
              With years of experience in the field, Green Power Solutions has
              honed its expertise in solar technology. Our team of engineers and
              technicians are well-versed in the latest advancements in solar
              lighting, ensuring that we deliver cutting-edge solutions to our
              customers.
            </li>
            <li
              style={{
                fontSize: "1.1rem",
                marginBottom: "1rem",
              }}
            >
              <span style={{ fontWeight: "900" }}>
                {" "}
                2. Diverse Range of Products :
              </span>
              We offer a comprehensive range of solar street lights, catering to
              various requirements and preferences. Whether you need lights for
              residential streets, commercial areas, or even remote locations,
              we have the right product for you. Our products are customizable
              to suit your specific needs.
            </li>
            <li
              style={{
                fontSize: "1.1rem",
                marginBottom: "1rem",
              }}
            >
              <span style={{ fontWeight: "900" }}>
                {" "}
                3. Eco-Friendly Approach :
              </span>
              We are committed to environmental sustainability. Our solar street
              lights harness the power of the sun, reducing the reliance on
              non-renewable energy sources. This not only lowers your carbon
              footprint but also helps in contributing to a cleaner environment.
            </li>
            <li
              style={{
                fontSize: "1.1rem",
                marginBottom: "1rem",
              }}
            >
              <span style={{ fontWeight: "900" }}> 4. Cost-Efficiency :</span>
              Green Power Solutions' solar street lights are designed to be
              cost-effective. By utilizing solar energy, you can significantly
              reduce electricity bills, making our products an economically
              sound choice for both residential and commercial applications.
            </li>
            <li
              style={{
                fontSize: "1.1rem",
                marginBottom: "1rem",
              }}
            >
              <span style={{ fontWeight: "900" }}>
                {" "}
                5. Reliability and Durability :
              </span>
              Our products are built to last. We understand the importance of
              reliability, especially in outdoor environments. Our solar street
              lights are designed to withstand harsh weather conditions,
              ensuring longevity and minimal maintenance.
            </li>
            <li
              style={{
                fontSize: "1.1rem",
                marginBottom: "1rem",
              }}
            >
              <span style={{ fontWeight: "900" }}>
                {" "}
                6. Customization Options :
              </span>
              We know that one size doesn't fit all. That's why we offer
              customization options to tailor our products to your unique
              requirements. From light intensity to design aesthetics, we ensure
              your solar street lights meet your exact specifications.
            </li>
          </ul>
          <p
            style={{
              marginTop: "1rem",
              fontSize: "1.1rem",
            }}
          >
            At Green Power Solutions, we are passionate about paving the way for
            a brighter and more sustainable future. We believe that solar street
            lights are not just a means to illuminate streets but also a symbol
            of responsible energy consumption. By choosing our products, you
            join us in this mission to create a cleaner, greener, and more
            energy-efficient world. <br />
            So, whether you're a municipality looking to light up your streets
            or a business owner aiming to enhance security and aesthetics, Green
            Power Solutions is your go-to partner for top-quality solar street
            lights. Illuminate your world with the power of the sun - choose
            Green Power Solutions and be part of the renewable energy
            revolution. <br />
            Contact us today to learn more about our solar street lights and how
            we can tailor a lighting solution that suits your needs. Together,
            let's light the way to a sustainable future.
          </p>
        </div>
      </section>
      <div className="programs__button">
        <Link to="/contact" className=" btn ">
          Contact Us
        </Link>
      </div>
    </>
  );
};

export default StreetLight;
