import { BrowserRouter, Routes, Route } from "react-router-dom";
import { React } from "react";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import Plans from "./pages/plans/Plans";
import NotFound from "./pages/notFound/NotFound";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import Store from "./pages/store/Store";
import Solution from "./pages/solution/Solution";
import Applications from "./pages/applications/Applications";
import Finance from "./pages/finance/Finance";
import Projects from "./pages/projects/Projects";
import Support from "./pages/support/Support";
import Services from "./services/Services";
import Auditing from "./pages/auditing/Auditing";
import Sequestration from "./pages/sequestration/Sequestration";
import CarbonCredits from "./pages/carbonCredits/CarbonCredits";
import Money from "./pages/money/Money";
import Manufacturing from "./pages/manufacturing/Manufacturing";
import StreetLight from "./pages/manufacturing/StreetLight";

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="store" element={<Store />} />
        <Route path="contact" element={<Contact />} />
        <Route path="plans" element={<Plans />} />
        <Route path="solution" element={<Solution />} />
        <Route path="applications" element={<Applications />} />
        <Route path="finance" element={<Finance />} />
        <Route path="projects" element={<Projects />} />
        <Route path="support" element={<Support />} />

        <Route path="*" element={<NotFound />} />
        <Route path="services" element={<Services />} />
        <Route path="auditing" element={<Auditing />} />
        <Route path="sequestration" element={<Sequestration />} />
        <Route path="credits" element={<CarbonCredits />} />
        <Route path="money" element={<Money />} />
        <Route path="manufacturing" element={<Manufacturing />} />
        <Route path="light" element={<StreetLight />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
