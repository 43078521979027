import React from "react";
import Accordion from "./Accordian"; // Import the Accordion component

const AccordionList = () => {
  const accordionData = [
    {
      title:
        " What is the difference between carbon sequestration and carbon offsetting?",
      content:
        "Carbon sequestration involves the capture and storage of carbon in natural or artificial reservoirs, while carbon offsetting involves the purchase of carbon credits to offset emissions. Carbon sequestration is a more permanent solution than carbon offsetting, as it reduces the amount of CO2 in the atmosphere.",
    },
    {
      title:
        " Can carbon sequestration be used to offset emissions from fossil fuel use?",
      content:
        "Yes, carbon sequestration can be used to offset emissions from fossil fuel use. Carbon capture and storage (CCS) technologies can capture CO2 emissions from power plants and store them in geological formations.",
    },
    {
      title: "How much carbon can be sequestered through natural methods?",
      content:
        "The amount of carbon that can be sequestered through natural methods depends on various factors such as land use, soil type, and climate. According to the Intergovernmental Panel on Climate Change (IPCC), natural solutions such as forests and soil have the potential to sequester up to 12 gigatons of CO2 per year.",
    },
    {
      title:
        "What are the challenges of implementing carbon sequestration technologies?",
      content:
        "The challenges of implementing carbon sequestration technologies include high costs, technical feasibility, regulatory barriers, and public acceptance. Additionally, some technologies such as CCS require significant amounts of energy and resources to operate.",
    },
    {
      title: "What are the co-benefits of carbon sequestration?",
      content:
        "The co-benefits of carbon sequestration include improved soil health, biodiversity, water quality, and ecosystem services. Additionally, carbon sequestration can create new economic opportunities such as sustainable forestry and agriculture.",
    },
    {
      title: "How can individuals contribute to carbon sequestration?",
      content:
        "Individuals can contribute to carbon sequestration by planting trees, supporting sustainable agriculture and forestry practices, reducing food waste, and adopting a low-carbon lifestyle. Additionally, individuals can support policies that promote carbon sequestration and reduce greenhouse gas emissions.",
    },
  ];

  return (
    <div className="accordion-list">
      {accordionData.map((item, index) => (
        <Accordion key={index} title={item.title} content={item.content} />
      ))}
    </div>
  );
};

export default AccordionList;
