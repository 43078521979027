import Carousel from "better-react-carousel";
import React from 'react'
import First from '../images/picture/1st.jpg'
import Second from '../images/picture/2nd.jpg'
import Third from '../images/picture/3rd.jpg'
import Fourth from '../images/picture/4th.jpg'
import Fifth from '../images/picture/5th.jpg'
import Sixth from '../images/picture/6th.jpg'
import './image.css'
const ImageSlide = () => {
  return (
    <>
    <div className="image__slide">
    <Carousel  cols={2} rows={1} gap={0}loop showDots  autoPlay={2000} dotColorActive={'#17a58d'} className='gallery__control' >
      <Carousel.Item>
        <img width="100%" src={First} loading='lazy' />
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" src={Second} loading='lazy' />
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" src={Third} loading='lazy' />
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" src={Fourth} loading='lazy' />
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" src={Fifth} loading='lazy' />
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" src={Sixth} loading='lazy' />
      </Carousel.Item>
      
    </Carousel>
    </div>

    </>
  )
}

export default ImageSlide