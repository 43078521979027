import React from 'react'

const Support = () => {
  return (
<>
    <section>
      <h1 style={{
        textAlign:"center"
      }}>Support</h1>
      <p style={{
        textAlign:"center",
        margin:"4rem"
      }}>If you encounter any UI bugs, contact developer at : rrrrratul@gmail.com <br /> Or use the contact us section of the website</p>
    </section>

</>  )
}

export default Support