import React, { useState } from "react";
import "./Accordian.css";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";

const Accordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`accordion ${isOpen ? "open" : ""}`}>
      <div className="accordion-header" onClick={toggleAccordion}>
        <h4
          style={{
            fontSize: "1.2rem",
            fontWeight: "normal",
          }}
        >
          {title}
        </h4>
        <span
          style={{
            fontSize: "1.6rem",
            color: "orange",
          }}
        >
          {isOpen ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />}
        </span>
      </div>
      {isOpen && <div className="accordion-content">{content}</div>}
    </div>
  );
};

export default Accordion;
