import Aman from '../../images/Aman.png'
import Hemant from '../../images/hemant.jpg'
import {AiOutlineTeam} from 'react-icons/ai'

import './trainers.css'

const Trainers = () => {
  return (
<>


<section className='trainers__section'>
<h2 className='team__heading'>Our Team <span style={{color: "#17a58d"}}><AiOutlineTeam/></span>
</h2>
  <div className="container trainers__container">
    <div className="team__image">
      <img src={Aman} alt="" />
      <h3>Aman</h3>
    </div>
    <div className="team__description">
<p style={{
  padding : "2rem"
}} >
Founder : B. Tech in Mechanical Engineering, having multiple years of experience in the Solar sector and work experience with utility scale projects. Also developed a single axis tracking system during final year project which is currently under study by the project guide under Green India Initiative initiated by the Government of India. Worked on project site for captive use power plants as site in-charge.
</p>
    </div>
  </div>
  <div className="container trainers__container">
    <div className="team__image">
      <img src={Hemant} alt="" />
      <h3>Hemant</h3>
    </div>
    <div className="team__description">
<p style={{
  padding : "2rem"
}} >
Partner : Being a double master’s student in Business Management and Finance from Bangor University, Wales and Management Development Institute of Singapore brings his expertise in planning strategies for continuous growth and expansions. His out of the box thinking and sales expertise gives Green Power Solution the edge it needs in the current marketspace to thrive.
</p>
    </div>
  </div>
 


</section>
</>
    )
}

export default Trainers