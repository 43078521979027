import React from 'react'
import Solar from '../images/solar-header-op.jpg'



import './banner.css'
const Banner = () => {
  return (
    <>
    
      <div className="landing">
      <img src={Solar} alt="Green Power Solutions" />
      <div className="banner__content">
        <h1>Sustaining A <span>Future</span> <br /> That Looks Happier With Solar 
        </h1>
       
      </div>
      </div>
      </>
  )
}

export default Banner