import React from "react";
import Accordion from "./Accordian"; // Import the Accordion component

const AccordionList = () => {
  const accordionData = [
    {
      title: "What is the current price of carbon credits?",
      content:
        "The price of carbon credits varies depending on the exchange and the type of carbon credit. As of May 2023, the price of a European Union Allowance (EUA) is around €60.",
    },
    {
      title: "How do I buy carbon credits?",
      content:
        "You can buy carbon credits through a broker or through an exchange. You will need to open an account with the broker or exchange and provide identification and proof of address.",
    },
    {
      title: "Can I trade carbon credits as an individual?",
      content:
        " Yes, you can trade carbon credits as an individual, but it is recommended that you do so through a broker.",
    },
    {
      title:
        " What is the difference between a carbon offset and a carbon credit?",
      content:
        "A carbon offset is a way to compensate for carbon emissions by funding projects that reduce emissions. A carbon credit is a tradable certificate that represents a reduction in emissions.",
    },
    {
      title: "What is the future of carbon trading?",
      content:
        "Carbon trading is expected to grow as countries and companies around the world adopt more ambitious climate targets.",
    },
    {
      title: "Are there any risks involved in trading carbon credits?",
      content:
        "Yes, like any investment, trading carbon credits involves some risks. The price of carbon credits can be volatile, and there is the risk of fraud or market manipulation.",
    },
  ];

  return (
    <div className="accordion-list">
      {accordionData.map((item, index) => (
        <Accordion key={index} title={item.title} content={item.content} />
      ))}
    </div>
  );
};

export default AccordionList;
