import React from 'react'
import Tailored1 from '../../images/tailored1.png'
import Tailored2 from '../../images/tailored2.png'
import './finance.css'
const Finance = () => {
  return (
    <>
    <section>
        <div className="container finance__container">
          <h1 style={{
            textAlign:"center",
            paddingBottom:"1rem"
            
          }}>Tailored Finance Options</h1>
        <img src={Tailored1} alt="Tailored Finance" />
        <img src={Tailored2} alt="Tailored Finance" />
        </div>
    </section>
    </>
  )
}

export default Finance