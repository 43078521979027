import { ImPower, ImHome } from "react-icons/im";
import {
  FaSolarPanel,
  FaRupeeSign,
  FaBusinessTime,
  FaThermometerThreeQuarters,
  FaLeaf,
  FaEnvira,
} from "react-icons/fa";
import {
  GiSolarPower,
  GiWaterTank,
  GiHotSpices,
  GiDeskLamp,
  GiStreetLight,
} from "react-icons/gi";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { AiFillTool, AiFillCar } from "react-icons/ai";
import {
  BsFillGrid1X2Fill,
  BsGraphUpArrow,
  BsFillPeopleFill,
} from "react-icons/bs";
import { IoIosPeople } from "react-icons/io";
import { TbBrandCarbon } from "react-icons/tb";
import { MdPrecisionManufacturing } from "react-icons/md";

export const links = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "About",
    path: "/about",
  },

  {
    name: "Services",
    path: "/plans",
  },

  {
    name: "Projects",
    path: "/projects",
  },
  {
    name: "Contact",
    path: "/contact",
  },
];

export const programs = [
  {
    id: 1,
    icon: <ImPower />,
    title: "Solar PV Solutions For Electricity Generation",
    info: "Generation of Clean and green Electricity with nil impact on the environment.",
    path: "/solution",
  },
  {
    id: 2,
    icon: <FaSolarPanel />,
    title: "Solar Application",
    info: "put to application various techniques to help utilise the Sun as much  ",
    path: "/applications",
  },
  {
    id: 3,
    icon: <FaRupeeSign />,
    title: "Tailored Finance Options",
    info: "Multiple Finance Options",
    path: "/finance",
  },
  {
    id: 4,
    icon: <TbBrandCarbon />,
    title: "Carbon Auditing",
    info: "Measuring Your Carbon Footprint for a Sustainable Future",
    path: "/auditing",
  },
  {
    id: 5,
    icon: <TbBrandCarbon />,
    title: "Carbon Sequestration",
    info: "An Ultimate Guide to Combatting Climate Change",
    path: "/sequestration",
  },
  {
    id: 6,
    icon: <TbBrandCarbon />,
    title: "Carbon Credits",
    info: "Registration, Validation and Trade of Carbon Credits: Understanding the Basics",
    path: "/credits",
  },
  {
    id: 7,
    icon: <TbBrandCarbon />,
    title: "Making Money From Carbon Credits",
    info: "Are you looking for a new way to make money? Have you considered trading carbon credits? ",
    path: "/money",
  },
  {
    id: 8,
    icon: <MdPrecisionManufacturing />,
    title: "Solar PV Manufacturing",
    info: "India’s leading solar PV module manufacturer, with a total installed module manufacturing capacity of 15MW.",
    path: "/manufacturing",
  },
  {
    id: 9,
    icon: <GiStreetLight />,
    title: "Solar Street Light Manufacturing",
    info: "Illuminate the Future with Green Power Solutions - Your Expert in Solar Street Lights (Solar Street Light Manufacturing)",
    path: "/light",
  },
];

export const values = [
  {
    id: 1,
    icon: <GiSolarPower />,
    title: "Solar Panel Service",
    desc: "Green Power Solutions is proficient in delivering non-corrosive, consistent, and long-lasting solar panels that aim to convene excellent solar solutions.",
  },

  {
    id: 2,
    icon: <VscWorkspaceTrusted />,
    title: "Extended Guarantee",
    desc: "Green Power Solutions adheres to an absolute client satisfaction policy and thus every solar solution we proliferate comes with an add-on extended guarantee.",
  },
];

export const choose = [
  {
    id: 1,
    icon: <GiSolarPower />,
    title: "Long Time to Use",
    desc: "Our hard-wearing and damage-resistant solar panels guarantees on an extended time-period of usage. ",
  },
  {
    id: 2,
    icon: <GiSolarPower />,
    title: "Always Clean Energy",
    desc: "Creating solar solutions we promise to make this world a better place with less toxic effluence.",
  },
  {
    id: 3,
    icon: <GiSolarPower />,
    title: "Energy Can Be Reused",
    desc: "Identifying the reusable nature of solar energy, we are trying to curb power problems to our best.",
  },
  {
    id: 4,
    icon: <GiSolarPower />,
    title: "Used For Many Purposes",
    desc: "We endeavor to generate multifaceted solar solutions and channelize them for various purposes. ",
  },
  {
    id: 5,
    icon: <GiSolarPower />,
    title: "Low Installation Cost",
    desc: "Driven with a passion to re-create a sustainable future, we have kept our installation costs at minimal rates. ",
  },

  {
    id: 6,
    icon: <GiSolarPower />,
    title: "Easy Installatiion",
    desc: "Seasoned experts at Green Power Solutions ensure easy installation for domestic and commercial uses. ",
  },
];

export const solution = [
  {
    id: 1,
    icon: <ImHome />,
    title: "Domestic",
    desc: "Specialised solutions for home and domestic applications.",
  },
  {
    id: 2,
    icon: <FaBusinessTime />,
    title: "Commercial & Institutions",
    desc: "Specialised solutions for Industries, Factories",
  },
  {
    id: 3,
    icon: <AiFillTool />,
    title: "Utility",
    desc: "Turnkey Service for Utility scale projects",
  },
  {
    id: 4,
    icon: <BsFillGrid1X2Fill />,
    title: "Microgrid",
    desc: "For rural and isolated locations to provide solutions off the grid",
  },
  {
    id: 5,
    icon: <AiFillCar />,
    title: "Car port & Special Structures",
    desc: "Car Parking space generates electricity, Solar trees and specially engineered structures to accommodate challenging locations.",
  },
];

export const applications = [
  {
    id: 1,
    icon: <GiWaterTank />,
    title: "Water Pumps",
    desc: "Turnkey service for powering water pumps, with agriculture contributing in a big part we aim to provide them with solutions that enable them to use water pumps remotely helping them work at ease.",
  },
  {
    id: 2,
    icon: <GiHotSpices />,
    title: " Water Heating System",
    desc: "We provide water heating solutions for domestic and commercial use providing heated water without using electricity.",
  },
  {
    id: 3,
    icon: <GiDeskLamp />,
    title: "Street Light",
    desc: "Providing smart street light solutions to help in energy savings while fulfilling the need of providing best illumination service. ",
  },
  {
    id: 4,
    icon: <FaThermometerThreeQuarters />,
    title: "Solar Thermal Application",
    desc: "Solutions for thermal application to help industries utilise solar energy for various applications like drying, boiling water etc.",
  },
];

export const advantage = [
  {
    id: 1,
    icon: <GiSolarPower />,
    title: "Why Solar Energy?",
    desc: "Understanding the grave pictures of the current times where power problems have risen to a peak due to illegal atrocities, Green Power Solutions believes in generating renewable solar energy solutions in order to save the world from a dark reality of power nonexistence. ",
  },
  {
    id: 2,
    icon: <GiSolarPower />,
    title: "Advantage Of Solar Energy",
    desc: "Green Power Solutions comprehends the innumerous advantages solar energy can provide to our nature. Winging from a multi-dimensional nature, we further re-fabricate solar solutions to facilitate a greener environment with no pollution and potential, renewable power energy.  ",
  },
  {
    id: 3,
    icon: <GiSolarPower />,
    title: "Solar System Benefits",
    desc: "Deriving clean, pure and renewable source of energy from the sun, Green Power Solutions undertakes a ready for action battle against greenhouse gas emissions and eradicating traditional electricity that is on the verge of extinction. ",
  },
  {
    id: 4,
    icon: <GiSolarPower />,
    title: "Increase in property value:",
    desc: "In addition to huge saving benefits, getting a solar installation can offer a benefit of an increase in property value. Therefore, while selling, leasing, or renting the property can give you the advantage of claiming a better cost in return. Solar installation can last long up to 30 years where a person can enjoy saving benefits on hefty electricity bills.",
  },
  {
    id: 5,
    icon: <GiSolarPower />,
    title: "Energy Independence:",
    desc: "Even though India today may be called as sufficient energy developer but in reality, the lack of proper infrastructure can result in a power shortage in many remote as well as urban areas in the country. Solar installation can offer an independent structure of energy to your property without spending tons of money on traditional power grid lines.",
  },
  {
    id: 6,
    icon: <GiSolarPower />,
    title: "Tax Benefits",
    desc: "Investors in the solar installation are eligible for accelerated tax depreciation during their financial year in which the installation has taken place. Besides these benefits, income from solar energy can be exempted from tax for the next 10 consecutive years. This provision can vary from state to state.",
  },
  {
    id: 7,
    icon: <GiSolarPower />,
    title: "Solar power is cheaper than the electricity grid.",
    desc: "Thermal energy production comes more costly contrarily than rooftop solar energy which offers a one-time investment for your property. Since solar technology has moved very far in India, it is sufficient enough to meet one’s daily consumption of electricity. A person can save money with the production of solar electricity at home. With the constant rise in the price of electricity, it is a better option available to consumers for their electricity needs. Developing countries like India offer a good amount of Return on Investment with better financial security to the homeowners.",
  },
  {
    id: 8,
    icon: <GiSolarPower />,
    title: "Government Incentives",
    desc: "Those who wish to go for solar panel installation can avail the sector loan of amount 10 lakhs from nationalized banks. The current policy of the Ministry of New and Renewable, the central government offers 30 per cent of the installation cost for the rooftop PV system. Both the central and state government provides a subsidy to the people installing rooftop PV system because it encourages people to adopt the renewable sources of energy for their homes and cut their electricity bills into half the amount. Some states which come under special categories such as Uttarakhand, Sikkim, Himachal Pradesh, Jammu & Kashmir get a subsidy of 70 per cent for installation cost. These schemes are available only for residential, institutional and social sectors.",
  },
  {
    id: 9,
    icon: <GiSolarPower />,
    title: "Benefiting With Our Mission",
    desc: "At Green Power Solutions every individual is passionate about improving our home through high-quality renewable energy. That’s why we’re invested in building and managing state-of-the-art energy systems and storage solutions that maximize production for optimal results. Each project is an opportunity to provide cleaner power, better jobs, greater education, and a brighter future for our community. Powerful New Brand: Bold curiosity led us to where we are today. Our relentless quest to innovate will propel us to a better tomorrow. The next era of renewable energy is here. We are Green Power Solutions. Bigger, better, on our own, and more determined than ever.",
  },
];

export const sustainability = [
  {
    id: 1,
    icon: <BsGraphUpArrow />,
    title: "Business",
    desc: "Position the company for sustainable growth by combining long-term strategic thinking with focused short-term action​. ",
  },
  {
    id: 2,
    icon: <FaLeaf />,
    title: "Environment ",
    desc: "Manage our activities to maximise the environmental benefits of our projects and our business​.",
  },
  {
    id: 3,
    icon: <IoIosPeople />,
    title: "Social",
    desc: "Provide a safe and healthy work environment which encourages employee development and creates opportunities to positively contribute to our stakeholders and the wider community​.",
  },
];
export const audit = [
  {
    id: 1,
    icon: <FaEnvira />,
    title: "Environmental Benefits",
    desc1: "Reducing GHG emissions and slowing down climate change ",
    desc2:
      "Protecting ecosystems and biodiversity from the impacts of climate change ",
    desc3: "Conserving natural resources such as water, energy, and materials",
    desc4: "Promoting sustainable land use and agriculture practices",
  },
  {
    id: 2,
    icon: <FaRupeeSign />,
    title: "Economic Benefits",
    desc1: "Saving money on energy bills and operational costs",

    desc2: "Increasing energy efficiency and productivity",

    desc3: "Improving brand reputation and customer loyalty",
    desc4: "Enhancing competitiveness and access to new markets",
  },

  {
    id: 3,
    icon: <BsFillPeopleFill />,
    title: "Social Benefits",
    desc1: "Creating green jobs and new business opportunities",
    desc2:
      "Improving public health and well-being by reducing air and water pollution",
    desc3: "Strengthening community resilience to climate change impacts",
    desc4: "Fostering social responsibility and environmental awareness",
  },
];

export const faqs = [
  {
    id: 1,
    question: "How often should I exercise?",
    answer:
      "Consectetur adipisicing elit. Non ipsa dolorem, rem consequatur eum omnis ex, fuga temporibus qui nesciunt odio aliquam commodi culpa inventore ut similique repellendus perferendis sint!",
  },
  {
    id: 2,
    question: "What time of day is best to work out?",
    answer:
      "Distinctio nihil blanditiis accusantium atque, quo maxime inventore eum! Cum dolorem quibusdam amet et qui. Eos, omnis beatae? Quas, est at! Molestiae quidem ab soluta exercitationem culpa nostrum iusto illum qui non a harum deserunt atque commodi at velit.",
  },
  {
    id: 3,
    question: "How long should my workouts be?",
    answer:
      "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.",
  },
  {
    id: 4,
    question: "Do I need to warm up before my workouts?",
    answer:
      "Molestiae quidem ab soluta exercitationem culpa nostrum iusto illum qui non a harum deserunt atque commodi at velit, consequatur quibusdam dignissimos cum labore possimus placeat consectetur nisi cupiditate? Qui totam est omnis dolor nobis quisquam veritatis a!",
  },
  {
    id: 5,
    question: "Should I do strength training, cardio or both?",
    answer:
      "Maiores fuga, cum praesentium esse laudantium! Distinctio nihil blanditiis accusantium atque, quo maxime inventore eum! Cum dolorem quibusdam amet et qui.",
  },
  {
    id: 6,
    question: "Should I lift weights for strength training?",
    answer:
      "Quas, est at! Molestiae quidem ab soluta exercitationem culpa nostrum iusto illum qui non a harum deserunt atque commodi at velit, consequatur quibusdam dignissimos cum labore possimus placeat consectetur nisi cupiditate.",
  },
];

export const testimonials = [
  {
    id: 1,
    name: "ODM School",
    quote:
      "A very professional and responsible company had executed our work with utmost care and safety for students ",
    job: "Deepak  Mishra",
    avatar: require("./images/represent.png"),
  },
  {
    id: 2,
    name: " Modello High",
    quote: "Good service and timely execution with one point solution ",
    job: " Biplap Mondal",
    avatar: require("./images/represent.png"),
  },
  {
    id: 3,
    name: "Ekta Projects",
    quote:
      "Great design and conceptualization for our tricy terrace to provide renewable energy",
    job: "Jatin Agarwal",
    avatar: require("./images/represent.png"),
  },
  {
    id: 4,
    name: "Kariwala Industries Ltd",
    quote:
      "Great professionals who are just a call away to provide sevice. We have always come back to them for various installations we have executed for our facility",
    job: "Ranjit Singh",
    avatar: require("./images/represent.png"),
  },
  {
    id: 5,
    name: "Manmohini Nursing Acadmy",
    quote: "Amazing individuals with great design and execution abilities.",
    job: "Girish Gulgullia",
    avatar: require("./images/represent.png"),
  },
  {
    id: 6,
    name: "Manmohini Textile",
    quote: "One stop shop for your solar requirements !",
    job: "Girish Gulgullia",
    avatar: require("./images/represent.png"),
  },
  {
    id: 7,
    name: "Shantan Ashram",
    quote:
      "Helpful company who assisted ashram to generate energy for self consumption and for benefit of students in the ashram",
    job: "Swami Atmananda",
    avatar: require("./images/represent.png"),
  },
  {
    id: 8,
    name: "The Akshay Patra Foundation",
    quote:
      "Best support service we have seen they helped us revive our solar plant when our original vendor refused service",
    job: "Kamal Deka",
    avatar: require("./images/represent.png"),
  },
  {
    id: 9,
    name: "Lalit PolyWeave ",
    quote:
      "Best idea planning and execution, they have helped us same huge costs for electricity for our manufacturing facility",
    job: "Kushal Agarwal",
    avatar: require("./images/represent.png"),
  },

  {
    id: 10,
    name: "Avishek Polyweave",
    quote:
      "Saviours in this modern time of high maintenance. They have helped us successfully lower our energy consumption and are exceptional as Asset managers",
    job: "Rajan Maheshwari",
    avatar: require("./images/represent.png"),
  },
  {
    id: 11,
    name: "Platinum Tea Portfolio",
    quote:
      "Best service providing vendor must try for all consumers looking for solar energy soltions",
    job: "Ankit Baheti",
    avatar: require("./images/represent.png"),
  },
  {
    id: 12,
    name: "Uttrayan Ville",
    quote:
      "Exceptional service , its very difficult to get company like this in todays time.",
    job: "Rakesh Choudhary",
    avatar: require("./images/represent.png"),
  },
  {
    id: 13,
    name: "Sunghandha Nirman",
    quote:
      "Our facility has been lit up by their solution and continues to exceed our expectations.",
    job: "Ayush Rathi",
    avatar: require("./images/represent.png"),
  },
  {
    id: 14,
    name: "Courtyard by Marriot (Shillon)",
    quote:
      "It was a hard task to provide turnkey solution with al logistical and orther situations, but this did not stop this energetic company to provide and execute a solution custom made for us. Truly one stop solution for your energy needs",
    job: "Dhirein Singh",
    avatar: require("./images/represent.png"),
  },
];

export const plans = [
  {
    id: 1,
    name: "Business Services",
    desc: "This package is perfect for beginners who need constant help",
    price: 29.99,
    features: [
      { feature: "First Feature", available: true },
      { feature: "Second Feature", available: true },
      { feature: "Third Feature", available: true },
      { feature: "Fourth Feature", available: true },
      { feature: "Fifth Feature", available: true },
      { feature: "Fifth Feature Plus", available: false },
      { feature: "Sixth Feature", available: false },
      { feature: "Seventh Feature", available: false },
      { feature: "Seventh Feature Plus", available: false },
      { feature: "Eighth Feature", available: false },
      { feature: "Ninth Feature", available: false },
      { feature: "Tenth Feature", available: false },
      { feature: "Eleventh Feature", available: false },
    ],
  },
  {
    id: 2,
    name: "Gold Package",
    desc: "This is the perfect package for beginners who know what their doing",
    price: 49.99,
    features: [
      { feature: "First Feature", available: true },
      { feature: "Second Feature", available: true },
      { feature: "Third Feature", available: true },
      { feature: "Fourth Feature", available: true },
      { feature: "Fifth Feature", available: true },
      { feature: "Fifth Feature Plus", available: true },
      { feature: "Sixth Feature", available: true },
      { feature: "Seventh Feature", available: true },
      { feature: "Seventh Feature Plus", available: true },
      { feature: "Eighth Feature", available: false },
      { feature: "Ninth Feature", available: false },
      { feature: "Tenth Feature", available: false },
      { feature: "Eleventh Feature", available: false },
    ],
  },
  {
    id: 3,
    name: "Platinum Package",
    desc: "This package is perfect for busy people who need home service",
    price: 89.99,
    features: [
      { feature: "First Feature", available: true },
      { feature: "Second Feature", available: true },
      { feature: "Third Feature", available: true },
      { feature: "Fourth Feature", available: true },
      { feature: "Fifth Feature", available: true },
      { feature: "Fifth Feature Plus", available: true },
      { feature: "Sixth Feature", available: true },
      { feature: "Seventh Feature", available: true },
      { feature: "Seventh Feature Plus", available: true },
      { feature: "Eighth Feature", available: true },
      { feature: "Ninth Feature", available: true },
      { feature: "Tenth Feature", available: true },
      { feature: "Eleventh Feature", available: true },
    ],
  },

  {
    id: 4,
    name: "Silver Package",
    desc: "This package is perfect for beginners who need constant help",
    price: 29.99,
    features: [
      { feature: "First Feature", available: true },
      { feature: "Second Feature", available: true },
      { feature: "Third Feature", available: true },
      { feature: "Fourth Feature", available: true },
      { feature: "Fifth Feature", available: true },
      { feature: "Fifth Feature Plus", available: false },
      { feature: "Sixth Feature", available: false },
      { feature: "Seventh Feature", available: false },
      { feature: "Seventh Feature Plus", available: false },
      { feature: "Eighth Feature", available: false },
      { feature: "Ninth Feature", available: false },
      { feature: "Tenth Feature", available: false },
      { feature: "Eleventh Feature", available: false },
    ],
  },
  {
    id: 5,
    name: "Silver Package",
    desc: "This package is perfect for beginners who need constant help",
    price: 29.99,
    features: [
      { feature: "First Feature", available: true },
      { feature: "Second Feature", available: true },
      { feature: "Third Feature", available: true },
      { feature: "Fourth Feature", available: true },
      { feature: "Fifth Feature", available: true },
      { feature: "Fifth Feature Plus", available: false },
      { feature: "Sixth Feature", available: false },
      { feature: "Seventh Feature", available: false },
      { feature: "Seventh Feature Plus", available: false },
      { feature: "Eighth Feature", available: false },
      { feature: "Ninth Feature", available: false },
      { feature: "Tenth Feature", available: false },
      { feature: "Eleventh Feature", available: false },
    ],
  },
  {
    id: 6,
    name: "Silver Package",
    desc: "This package is perfect for beginners who need constant help",
    price: 29.99,
    features: [
      { feature: "First Feature", available: true },
      { feature: "Second Feature", available: true },
      { feature: "Third Feature", available: true },
      { feature: "Fourth Feature", available: true },
      { feature: "Fifth Feature", available: true },
      { feature: "Fifth Feature Plus", available: false },
      { feature: "Sixth Feature", available: false },
      { feature: "Seventh Feature", available: false },
      { feature: "Seventh Feature Plus", available: false },
      { feature: "Eighth Feature", available: false },
      { feature: "Ninth Feature", available: false },
      { feature: "Tenth Feature", available: false },
      { feature: "Eleventh Feature", available: false },
    ],
  },
];

const Trainer1 = require("./images/male.png");
const Trainer2 = require("./images/male.png");

export const trainers = [
  {
    id: 1,
    image: Trainer1,
    name: "Shivam Sharma",
    job: "General Manager",
    desc: "No description for now",
    socials: [
      "https://instagram.com/",
      "https://twitter.com/",
      "https://facebook.com/",
      "https://linkedin.com/",
    ],
  },
  {
    id: 2,
    image: Trainer2,
    name: "Palak Sharma",
    job: "Managing Director",
    desc: "No description for now",
    socials: [
      "https://instagram.com/",
      "https://twitter.com/",
      "https://facebook.com/",
      "https://linkedin.com/",
    ],
  },
];

export const projects = [
  {
    id: 1,
    name: "Guwahati, Assam",
    size: "633KW",
    status: "Project Completed",
  },
  {
    id: 2,
    name: "Shree Agro Guwahati, Assam, ",
    size: "500KW",
    status: "Project Completed",
  },
  {
    id: 3,
    name: "LPW  Guwahati, Assam",
    size: "400KW",
    status: "Project Completed",
  },
  {
    id: 4,
    name: "APW Guwahati, Assam",
    size: "600KW",
    status: "Project Completed",
  },
  {
    id: 5,
    name: "KPRG Nekra Paschim Area, West Bengal",
    size: "330KW",
    status: "Project Completed",
  },
  {
    id: 6,
    name: "Sungandha Guwahati, Assam",
    size: "220KW",
    status: "Project Ongoing",
  },
  {
    id: 7,
    name: "ndian Oil Budge Budge, West Bengal ",
    size: "100KW",
    status: "Project Ongoing",
  },
  {
    id: 8,
    name: "Indian Oil Kalyani, West Bengal ",
    size: "60KW",
    status: "Project Ongoing",
  },
  {
    id: 9,
    name: "Indian Oil Vasco, Goa",
    size: "104KW",
    status: "Project Ongoing",
  },
  {
    id: 10,
    name: "Indian Oil Bangalore, West Bengal ",
    size: "100KW",
    status: "Project Ongoing",
  },
  {
    id: 11,
    name: "KOSC Baidyabati, West Bengal ",
    size: "150KW",
    status: "Project Completed",
  },
  {
    id: 12,
    name: "ODM School at Bhubaneshwar with Channel Partner, Orissa",
    size: "175KW",
    status: "Project Completed",
  },
  {
    id: 13,
    name: "JW Marriott, Shillong, Meghalaya",
    size: "30KW",
    status: "Project Completed",
  },
  {
    id: 14,
    name: "Ekta New Town, Kolkata, West Bengal ",
    size: "5KW",
    status: "Project Completed",
  },
  {
    id: 15,
    name: "Modellp Kamalgazi, Kolkata, West Bengal ",
    size: "12KW",
    status: "Project Completed",
  },
  {
    id: 16,
    name: "Kariwala Kalyani, West Bengal ",
    size: "45KW",
    status: "Project Completed",
  },
  {
    id: 17,
    name: "Kariwala Falta, West Bengal ",
    size: "51KW",
    status: "Project Completed",
  },
  {
    id: 18,
    name: "Apex Academy, Mahishadal, West Bengal ",
    size: "25KW",
    status: "Project Completed",
  },
  {
    id: 19,
    name: "Platinum Lalmati, Guwahati, Assam ",
    size: "30KW",
    status: "Project Completed",
  },
  {
    id: 20,
    name: "The Akshay Patra Foundation, Guwahati, Assam ",
    size: "45KW",
    status: "Project Completed",
  },
  {
    id: 21,
    name: "Manmohini Behrampore, West Bengal",
    size: "43KW",
    status: "Project Completed",
  },
  {
    id: 22,
    name: "Kakurgachi, Kolkata, West Bengal",
    size: "1KW",
    status: "Project Completed",
  },
  {
    id: 23,
    name: "PCPL Neon Terraces, Mumbai, Maharashtra",
    size: "5.8KW",
    status: "Project Completed",
  },
  {
    id: 24,
    name: "Private residence Salt Lake City, Kolkata, West Bengal ",
    size: "10KW",
    status: "Project Ongoing",
  },
  {
    id: 25,
    name: "Flipkart, Haringhatha, West Bengal ",
    size: "20,000 Litres capacity Water Heater",
    status: "Project Completed",
  },
  {
    id: 26,
    name: "CESC, West Bengal",
    size: "Carbon Audit and Consultancy",
    status: "Project Completed",
  },
  // {
  //   id: 27,
  //   name: "Guwahati, Assam",
  //   size: "633KW",
  //   status: "Project Completed",
  // },
  // {
  //   id: 28,
  //   name: "Guwahati, Assam",
  //   size: "633KW",
  //   status: "Project Completed",
  // },
  // {
  //   id: 29,
  //   name: "Guwahati, Assam",
  //   size: "633KW",
  //   status: "Project Completed",
  // },
  // {
  //   id: 30,
  //   name: "Guwahati, Assam",
  //   size: "633KW",
  //   status: "Project Completed",
  // },
  // {
  //   id: 31,
  //   name: "Guwahati, Assam",
  //   size: "633KW",
  //   status: "Project Completed",
  // },
  // {
  //   id: 32,
  //   name: "Guwahati, Assam",
  //   size: "633KW",
  //   status: "Project Completed",
  // },
  // {
  //   id: 33,
  //   name: "Guwahati, Assam",
  //   size: "633KW",
  //   status: "Project Completed",
  // },
];
