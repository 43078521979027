import { Link } from "react-router-dom";
import Logo from "../images/logo.png";
import { FaLinkedin, FaFacebookF } from "react-icons/fa";
import { AiOutlineTwitter, AiFillInstagram } from "react-icons/ai";
import Brochure from "../../src/Brochure.pdf";

const Footer = () => {
  return (
    <footer>
      <div className="container footer__container">
        <article>
          <Link to="/" className="logo">
            <img src={Logo} alt="Footer logo" />
          </Link>
          <p>
            Green Power Solutions empowers domestic and commercial sectors to
            utilize the power of the sun with brilliant solar energy solutions
            and preserve the limited resources for the future to have a share of
            it too!
          </p>
          <div className="footer__socials">
            <a
              href="https://www.linkedin.com/company/greenpower-solutions/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FaLinkedin />
            </a>
            {/* <a
              href="https://facebook.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FaFacebookF />
            </a> */}
            {/* <a
              href="https://twitter.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <AiOutlineTwitter />
            </a> */}
            <a
              href="https://instagram.com/wearegreenpowersolutions?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr"
              target="_blank"
              rel="noreferrer noopener"
            >
              <AiFillInstagram />
            </a>
          </div>
        </article>

        <article>
          <h4>Permalinks</h4>
          <Link to="/about">About</Link>
          <Link to="/plans">Services</Link>
          <a href="http://store.greenpowersol.com">Online Store</a>
          <Link to="/projects">Projects</Link>
          <Link to="/contact">Contact</Link>
        </article>
        <article>
          <h4>Permalinks</h4>
          <Link to="/contact">Contact Us</Link>
          <a href={Brochure} download>
            Download Brochure
          </a>
          <Link to="support">Support</Link>
        </article>
        <article>
          <h4>Address</h4>
          <p>
            Head Office: Unit No. ECSL0712, Ambuja Neotia EcoCentre, 4, Eastern
            Metropolitan Bypass, Sector V, Bidhannagar, Kolkata, West Bengal
            700001
          </p>
        </article>
      </div>
      <div className="footer__copyright">
        <small>2022 GREEN POWER SOLUTIONS. &copy; All Rights Reserved</small>
      </div>
    </footer>
  );
};

export default Footer;
