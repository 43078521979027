import FAQs from "../../components/FAQs";
import Footer from "../../components/Footer";
import MainHeader from "../../components/MainHeader";
import Programs from "../../components/Programs";
import Testimonials from "../../components/Testimonials";
import Values from "../../components/Values";
import Info from "../../components/Info";
import Banner from "../../components/Banner";

import "./home.css";
import Calculator from "../../components/Calculator";
import Maps from "../../components/Maps";

const Home = () => {
  return (
    <>
      <Banner />

      <Values />
      <Info />
      <Calculator />

      <Maps />
      <Testimonials />
    </>
  );
};

export default Home;
