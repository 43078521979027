import React from "react";
import Accordion from "./Accordian"; // Import the Accordion component

const AccordionList = () => {
  const accordionData = [
    {
      title: "Who can benefit from carbon auditing?",
      content:
        "Any individual, organization, or activity that emits GHGs can benefit from conducting a carbon audit. This includes households, businesses, cities, governments, and events.",
    },
    {
      title: "What are the most common sources of GHG emissions?",
      content:
        "The most common sources of GHG emissions are energy use (electricity, heating, cooling, and transportation), industrial processes, agriculture, forestry, and waste management.",
    },
    {
      title: "How much does a carbon audit cost?",
      content:
        "The cost of a carbon audit depends on the scope, complexity, and quality of the data required. It can range from a few hundred to several thousand dollars for a professional audit. There are also free online tools and templates that can be used for a basic carbon footprint calculation.",
    },
    {
      title: "How often should a carbon audit be conducted?",
      content:
        "A carbon audit should be conducted at least once a year to track progress towards emission reduction targets. It should also be updated whenever there is a significant change in the scope or intensity of emissions.",
    },
    {
      title:
        "What are some common mitigation measures for reducing GHG emissions?",
      content:
        "Some common mitigation measures for reducing GHG emissions are:\n- Improving energy efficiency (e.g., insulation, LED lighting, smart controls)\n- Switching to renewable energy (e.g., solar, wind, geothermal)\n- Changing transportation modes (e.g., walking, cycling, public transit)\n- Reducing waste generation and disposal (e.g., composting, recycling, circular economy)\n- Adopting low-carbon technologies and practices (e.g., electric vehicles, green buildings, sustainable agriculture)",
    },
    {
      title: "What are some challenges and limitations of carbon auditing?",
      content:
        "Some challenges and limitations of carbon auditing are:\n- Data availability and quality can be variable and unreliable\n- Emission factors and methodologies can be complex and contested\n- The scope and boundaries of emissions can be difficult to define and compare\n- The costs and benefits of mitigation measures can vary depending on the context\n- The political and social acceptance of emission reduction targets and measures can be uncertain.",
    },
  ];

  return (
    <div className="accordion-list">
      {accordionData.map((item, index) => (
        <Accordion key={index} title={item.title} content={item.content} />
      ))}
    </div>
  );
};

export default AccordionList;
