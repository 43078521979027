import Header from "../../components/Header";
import "./about.css";
import { advantage, sustainability } from "../../data";
import HeaderImage from "../../images/solar-service.jpg";
import StoryImage from "../../images/about_1st-op.jpg";
import VisionImage from "../../images/about_2nd-op.jpg";
import { Link } from "react-router-dom";
import Card from "../../UI/Card";
import Trainers from "../team/Trainers";
import Calculator from "../../components/Calculator";
import ImageSlide from "../../components/ImageSlide";
import { GiStairsGoal } from "react-icons/gi";
import { FaEye } from "react-icons/fa";
import { GrGallery } from "react-icons/gr";
import Brochure from "../../../src/Brochure.pdf";

const About = () => {
  return (
    <>
      <Header title="About Us" image={HeaderImage}></Header>

      <section className="about__story">
        <div className="container about__story-container">
          <div className="about__section-image">
            <img src={StoryImage} alt="Story Image" />
          </div>
          <div className="about__section-content">
            <h1>
              Our Goals{" "}
              <span style={{ color: "#17a58d" }}>
                <GiStairsGoal />
              </span>{" "}
            </h1>
            <p>
              Green Power Solutions deploys productive solar solutions to shield
              the mother earth from escalating threats of pollution and
              reinforce domestic and commercial sectors with solar energy
              projects. Serving the dual benefits of trimming down electricity
              costs and preserving limited resources for the future, we aim to
              be counted amongst the most philanthropically advanced solar
              company.
            </p>
          </div>
        </div>
      </section>

      <section className="about__mission">
        <div className="container about__mission-container">
          <div className="about__section-image">
            <img src={VisionImage} alt="Story Image" />
          </div>
          <div className="about__section-content">
            <h1>
              Our Vision{" "}
              <span style={{ color: "#17a58d" }}>
                <FaEye />
              </span>{" "}
            </h1>
            <p>
              To emerge as the Go-To Global Service Provider for all Financial
              Services & successful implementation of Virtual CFO to handle all
              financial and regulatory aspects.
            </p>
            <p>
              To convert each engagement into a relationship and deliver
              personalized services custom made.
            </p>
          </div>
        </div>

        <section>
          <div className="updated__vision-container">
            <h3>
              "Our vision is a future where everyone has access to affordable
              zero carbon energy "
            </h3>
            <ul>
              <li>
                Passion, accountability, collaboration and excellence are our
                core values which define our culture and enable our vision. They
                drive performance and create an environment which is motivating
                for all our employees.
              </li>
              <li>
                Our sustainability agenda is "to power positive change by
                ensuring that our operations, products and services make a net
                positive contribution to society and the environment."
              </li>
              <li>
                This agenda is supported by principles in three core areas:
                Business, Environment and Social. Our business activities and
                vision are underpinned by our sustainability approach and our
                core values.
              </li>
            </ul>
          </div>
          <div className="updated__vision-container">
            <h4>
              Our <span>Sustainibilty</span> Approach
            </h4>
            <p>
              Since 2016 the 17 Sustainable Development Goals (SDGs) of the UN’s
              2030 Agenda for Sustainable Development have officially been in
              force. These goals guide countries, companies and society to
              mobilize efforts to end all forms of poverty, fight inequalities
              and tackle climate change, while ensuring that no one is left
              behind. We support all UN SDGs and focus our efforts on the goals
              that closely align with our strategic objectives and where we can
              deliver the greatest impact.
            </p>
          </div>
          <div className="advantage__wrapper">
            {sustainability.map(({ id, icon, title, desc }) => {
              return (
                <Card key={id} className="choose__value">
                  <span>{icon}</span>
                  <h4 data-aos="zoom-in-down">{title}</h4>
                  <small>{desc}</small>
                </Card>
              );
            })}
          </div>
        </section>
        <section>
          <div className="details__container">
            <h2>
              How Solar Makes a <span>Difference</span>
            </h2>
            <h5>
              Green Power Solutions exerts its solar activities indicating how
              preeminently it makes a difference:
            </h5>
            <div className="advantage__wrapper">
              {advantage.map(({ id, icon, title, desc }) => {
                return (
                  <Card key={id} className="choose__value">
                    <span>{icon}</span>
                    <h4 data-aos="zoom-in-down">{title}</h4>
                    <small>{desc}</small>
                  </Card>
                );
              })}
            </div>
          </div>
        </section>
        <section className="teams__container">
          <Trainers />
        </section>

        <section className="gallery__container">
          <h2
            style={{
              textAlign: "center",
            }}
          >
            Gallery{" "}
            <span style={{ color: "red" }}>
              <GrGallery />
            </span>
          </h2>
          <ImageSlide />
        </section>

        <section>
          <Calculator />
        </section>

        <div className="programs__button">
          <span>
            {" "}
            <Link to="/contact" className=" btn ">
              Contact Us
            </Link>{" "}
          </span>
          <span>
            {" "}
            <a href={Brochure} download className="btn">
              Download Brochure
            </a>{" "}
          </span>
        </div>
      </section>
    </>
  );
};

export default About;
