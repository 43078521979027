import React from "react";
import "./StepIndicator.css";

const CarbonAudit = () => {
  const steps = [
    {
      title: "Define the Scope",
      completed: true,
      text: "Decide what activities or processes you want to include in the audit, such as electricity consumption, transportation, waste disposal, or product manufacturing.",
    },
    {
      title: "Collect The Data",
      completed: true,
      text: "Gather information on the quantity and type of energy and resources used, as well as the GHG emissions associated with them. You can use tools such as energy bills, fuel receipts, or emission factors to estimate emissions.",
    },
    {
      title: "Analyze Data",
      completed: true,
      text: "Calculate the total amount of GHG emissions and break them down by source and sector. Identify the areas where emissions are high and potential mitigation measures.",
    },
    {
      title: "Set Reduction Targets",
      completed: false,
      text: "Based on the analysis, set realistic and achievable targets for reducing emissions over a certain period.",
    },
    {
      title: "Implement Measures",
      completed: false,
      text: "Take action to reduce emissions by improving energy efficiency, switching to renewable energy, changing behaviors, or adopting low-carbon technologies.",
    },
    {
      title: "Monitor and Report",
      completed: false,
      text: "Track progress towards the targets, review and adjust the measures as needed, and report on the results to stakeholders.",
    },
  ];

  return (
    <div className="carbon-audit">
      {steps.map((step, index) => (
        <div
          className={`step ${step.completed ? "completed" : ""}`}
          key={index}
        >
          <div className="step-number">{index + 1}</div>
          <div className="step-title">
            {step.title} {" : "}
          </div>
          <div className="step-title-text">{step.text}</div>
        </div>
      ))}
    </div>
  );
};

export default CarbonAudit;
