import React from "react";
import { BsSquareFill } from "react-icons/bs";

import Header from "../../components/Header";
import HeaderImage from "../../images/solar-header-op.jpg";

import Powerpalnt from "../../images/powerplant.jpg";
import Water from "../../images/water.jpg";
import Street from "../../images/street.jpg";
import Regency from "../../images/regency.jpg";
import Oberoi from "../../images/oberoi.jpg";
import Beauty from "../../images/beauty.jpeg";
import { projects } from "../../data";

// import './projects.scss'
import "./projects.css";

const Projects = () => {
  return (
    <>
      <Header title="Projects & Experience" image={HeaderImage}>
        <h1
          style={{
            textAlign: "center",
            fontSize: "1rem",
            padding: "1rem",
            color: "white",
            fontWeight: "200",
          }}
        >
          Take A Look At Our Recent Works
        </h1>
      </Header>

      <section>
        <div className="table-container">
          <table>
            <thead>
              <th>Project</th>
              <th>Size</th>
              <th>Status</th>
            </thead>
            <tbody>
              {projects.map((project) => (
                <tr key={project.id}>
                  <td>{project.name}</td>
                  <td>{project.size}</td>
                  <td>{project.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>

      <section>
        <div className="container parent__container">
          <div className="container work__container">
            <div className="text__work">
              <h3>330 kW Solar Power Plant</h3>
              <p>
                {" "}
                <span
                  style={{
                    marginRight: "0.2rem",
                  }}
                >
                  <BsSquareFill
                    style={{
                      color: "#f17108f9",
                    }}
                  />
                </span>{" "}
                330Kwp plant at Kolaghat, West Bengal.
              </p>
              <p>
                {" "}
                <span
                  style={{
                    marginRight: "0.2rem",
                  }}
                >
                  <BsSquareFill
                    style={{
                      color: "#f17108f9",
                    }}
                  />
                </span>{" "}
                Saving up to INR 38,00,000/ year.
              </p>
              <p>
                {" "}
                <span
                  style={{
                    marginRight: "0.2rem",
                  }}
                >
                  <BsSquareFill
                    style={{
                      color: "#f17108f9",
                    }}
                  />
                </span>{" "}
                Done at Zero Investment Model
              </p>
            </div>
            <div className="image__work">
              <img src={Powerpalnt} alt="" />
            </div>
          </div>
          <div className="container work__container">
            <div className="image__work">
              <img src={Water} alt="" />
            </div>
            <div className="text__work">
              <h3>Solar Water Heating System</h3>
              <p>
                {" "}
                <span
                  style={{
                    marginRight: "0.2rem",
                  }}
                >
                  <BsSquareFill
                    style={{
                      color: "#f17108f9",
                    }}
                  />
                </span>{" "}
                20,000 Litre capacity Solar water heating system set up for
                Flipkart.
              </p>
              <p>
                {" "}
                <span
                  style={{
                    marginRight: "0.2rem",
                  }}
                >
                  <BsSquareFill
                    style={{
                      color: "#f17108f9",
                    }}
                  />
                </span>{" "}
                Location: Haringhata, West Bengal.
              </p>
              <p>
                {" "}
                <span
                  style={{
                    marginRight: "0.2rem",
                  }}
                >
                  <BsSquareFill
                    style={{
                      color: "#f17108f9",
                    }}
                  />
                </span>{" "}
                Sustainable and efficient generation of Hot water.
              </p>
            </div>
          </div>
          <div className="container work__container">
            <div className="text__work">
              <h3>600 kW Solar Power Plant</h3>
              <p>
                {" "}
                <span
                  style={{
                    marginRight: "0.2rem",
                  }}
                >
                  <BsSquareFill
                    style={{
                      color: "#f17108f9",
                    }}
                  />
                </span>{" "}
                600Kwp plant at Chaygaon, Assam.
              </p>
              <p>
                {" "}
                <span
                  style={{
                    marginRight: "0.2rem",
                  }}
                >
                  <BsSquareFill
                    style={{
                      color: "#f17108f9",
                    }}
                  />
                </span>{" "}
                Saving up to INR 65,00,000/ year.
              </p>
              <p>
                {" "}
                <span
                  style={{
                    marginRight: "0.2rem",
                  }}
                >
                  <BsSquareFill
                    style={{
                      color: "#f17108f9",
                    }}
                  />
                </span>{" "}
                Done at Zero Investment Model
              </p>
            </div>
            <div className="image__work">
              <img src={Beauty} alt="" />
            </div>
          </div>
          <div className="container work__container">
            <div className="image__work">
              <img src={Street} alt="" />
            </div>
            <div className="text__work">
              <h3>Solar Street Light</h3>
              <h3> Installed in Assam:</h3>
            </div>
          </div>
          <div className="container work__container">
            <div className="text__work">
              <h3>Solar Rod Studs Installed</h3>
              <h3>In Hyatt Regency, Kolkata:</h3>
            </div>
            <div className="image__work">
              <img src={Oberoi} alt="" />
            </div>
          </div>
          <div className="container work__container">
            <div className="image__work">
              <img src={Regency} alt="" />
            </div>
            <div className="text__work">
              <h3>LED Supplied At</h3>
              <h3>Oberoi Grand, Kolkata:</h3>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Projects;
