import React from 'react'
import {applications} from '../../data'
import Card from '../../UI/Card'
import './applications.css'
import { Link } from 'react-router-dom'
import HeaderImage from '../../images/header33.jpg'
import Header from '../../components/Header'

const Applications = () => {
  return (
<>
<Header title="Solar Applications" image={HeaderImage}>
    </Header>
<div className="container applications__container">
  <h3>
  Sun has multipurpose functions, we help provide with applications of these. We put to application various techniques to help utilise the Sun as much  
  </h3>
<div className="applications__wrapper" >
                {
                    applications.map(({id, icon, title, desc}) => {
                        return <Card key={id} className="applications__value">
                            <span>{icon}</span>
                            <h4 >{title}</h4>
                            <small>{desc}</small>
                        </Card>
                    })
                }
                
            </div>
            <div className='programs__button'>
    <Link to="/contact" className=' btn '>Contact Us</Link>
    </div>

</div>
</>
  )
}

export default Applications
