import React from "react";
import HeaderImage from "../../images/solar-header-op.jpg";
import Header from "../../components/Header";
import CarbonAudit from "../../components/Steps";
import { audit } from "../../data";
import Card from "../../UI/Card";
import "../about/about.css";
import SeqAccordian from "../../components/SeqAccordian";
import { Link } from "react-router-dom";

const Auditing = () => {
  return (
    <>
      <Header title="Carbon Sequestration" image={HeaderImage}>
        Everything you need to know about carbon sequestration, its importance
        in mitigating climate change, and various techniques to achieve carbon
        sequestration. Climate change is an existential threat that requires
        immediate action. One of the most effective ways to combat climate
        change is through carbon sequestration. Carbon sequestration refers to
        the process of removing carbon dioxide (CO2) from the atmosphere and
        storing it in long-term reservoirs such as forests, soil, oceans, and
        geological formations. In this article, we will delve into the details
        of carbon sequestration, its importance in mitigating climate change,
        and various techniques to achieve carbon sequestration.
      </Header>
      <section>
        <div className="updated__vision-container">
          <h4>
            What is <span>Carbon Sequestration ? </span>
          </h4>
          <p>
            Carbon sequestration is the process of capturing and storing carbon
            dioxide (CO2) from the atmosphere in natural or artificial
            reservoirs. Natural reservoirs include forests, soil, and oceans,
            while artificial reservoirs include geological formations and carbon
            capture and storage (CCS) technologies. Carbon sequestration helps
            to reduce the amount of CO2 in the atmosphere, which is the primary
            cause of global warming and climate change.
          </p>
        </div>

        <div className="updated__vision-container">
          <h4>
            The <span>Importance</span> of Carbon Sequestration
          </h4>
          <p>
            Carbon sequestration is crucial for mitigating climate change.
            Carbon dioxide is a greenhouse gas that traps heat in the
            atmosphere, causing global temperatures to rise. The rise in
            temperatures is leading to catastrophic consequences such as
            sea-level rise, extreme weather events, and biodiversity loss. By
            sequestering carbon, we can reduce the amount of CO2 in the
            atmosphere and slow down global warming. Additionally, carbon
            sequestration can help to improve soil health, promote biodiversity,
            and create new economic opportunities.
          </p>
        </div>

        <div className="updated__vision-container">
          <h4>
            Carbon Sequestration <span>Techniques</span>
          </h4>
          <p>
            Carbon auditing has many benefits for individuals, businesses, and
            society as a whole. Here are some of them:
          </p>
        </div>
        <div className="updated__vision-container">
          <h4>
            <span>Natural</span> Carbon Sequestration :
          </h4>
          <h5>Forests and Afforestation</h5>
          <p>
            Forests are one of the most effective natural carbon sinks. Trees
            absorb CO2 through photosynthesis and store it in their biomass.
            Forests also provide various co-benefits such as biodiversity, water
            regulation, and timber production. Afforestation refers to the
            planting of new forests on land that was previously not forested.
          </p>
          <h5>Soil Carbon Sequestration</h5>
          <p>
            Soil has the potential to sequester large amounts of carbon through
            practices such as conservation tillage, cover cropping, and
            composting. Soil carbon sequestration not only helps to mitigate
            climate change but also improves soil health and fertility. Ocean
            Carbon Sequestration Oceans are one of the largest carbon sinks on
            the planet. Phytoplankton in the ocean absorb CO2 through
            photosynthesis and store it in their biomass. Additionally, the
            oceans absorb CO2 from the atmosphere, which leads to ocean
            acidification. Ocean carbon sequestration can help to mitigate ocean
            acidification and improve the health of marine ecosystems.
          </p>
        </div>
        <div className="updated__vision-container">
          <h4>
            <span>Artificial</span> Carbon Sequestration :
          </h4>
          <h5>Carbon Capture and Storage (CCS)</h5>
          <p>
            Carbon capture and storage (CCS) technologies capture CO2 from
            industrial processes such as power plants and store it in geological
            formations such as depleted oil and gas reservoirs or saline
            aquifers. CCS technologies can help to reduce CO2 emissions from
            industrial processes.
          </p>
          <h5>Direct Air Capture (DAC)</h5>
          <p>
            Direct air capture (DAC) technologies capture CO2 directly from the
            atmosphere and store it in geological formations or utilize it for
            industrial processes such as synthetic fuel production. DAC
            technologies can help to reduce CO2 concentrations in the
            atmosphere.
          </p>
        </div>

        <div className="updated__vision-container">
          <p>
            Carbon sequestration is a crucial tool for mitigating climate
            change. Natural and artificial carbon sequestration methods can help
            to reduce the amount of CO2 in the atmosphere and slow down global
            warming. However, implementing carbon sequestration technologies
            comes with its challenges, and it requires a concerted effort from
            governments, businesses, and individuals. By working together, we
            can combat climate change and create a sustainable future for
            ourselves and future generations.
          </p>
        </div>

        <div
          style={{
            marginBottom: "1rem",
          }}
          className="updated__vision-container"
        >
          <h4>
            <span>FAQs</span> About Carbon Audting
          </h4>
        </div>
        <div
          style={{
            marginRight: "6rem",
            marginLeft: "6rem",
          }}
        >
          <SeqAccordian />
        </div>
      </section>
      <div className="programs__button">
        <Link to="/contact" className=" btn ">
          Contact Us
        </Link>
      </div>
    </>
  );
};

export default Auditing;
