import React from 'react'
import './info.css'
import Rep from '../images/represent.png'
import { choose } from '../data'
import Card from '../UI/Card'   
import { useEffect } from 'react'
import Aos from "aos";
import "aos/dist/aos.css"



const Info = () => {
  useEffect(() => {
    Aos.init({duration: 900});
}, []);
  return (
    <>
    <section>
        <div className="container info__container">
        <div className="info__heading">
        <h2> <img src={Rep} alt="" className='info__image'/>Why<span className='changing__texts'></span>Us? </h2>

        </div>
        <p>
        Refurbishing our sincere and hard-labored initiative to make a world a better place to stay with our exceptional solar energy solutions, here are more reasons why you should choose us:
        </p>
        </div>
        <div className="choose__wrapper" >
                {
                    choose.map(({id, icon, title, desc}) => {
                        return <Card key={id} className="choose__value">
                            <span>{icon}</span>
                            <h4 data-aos="zoom-in-down">{title}</h4>
                            <small>{desc}</small>
                        </Card>
                    })
                }
                
            </div>
    </section>
    </>
  )
}

export default Info