import React from "react";
import Accordion from "./Accordian"; // Import the Accordion component

const AccordionList = () => {
  const accordionData = [
    {
      title:
        "Who can participate in the registration, validation, and trade of carbon credits?",
      content:
        "Any company or organization that wants to reduce its greenhouse gas emissions can participate in the registration, validation, and trade of carbon credits.",
    },
    {
      title:
        "What is the benefit of participating in the registration, validation, and trade of carbon credits?",
      content:
        "Participating in the registration, validation, and trade of carbon credits provides companies and organizations with an opportunity to reduce their greenhouse gas emissions and earn revenue from the sale of carbon credits.",
    },
    {
      title: "How are carbon credits priced?",
      content:
        "The price of carbon credits is determined by supply and demand. As the demand for carbon credits increases, the price increases.",
    },
    {
      title:
        "Can carbon credits be used to offset all greenhouse gas emissions?",
      content:
        "No, carbon credits cannot be used to offset all greenhouse gas emissions. Carbon credits are only one part of a comprehensive emissions reduction strategy. Companies and organizations must still take steps to reduce their greenhouse gas emissions.",
    },
    {
      title:
        "What are some of the challenges associated with the registration, validation, and trade of carbon credits?",
      content:
        "One of the main challenges associated with the registration, validation, and trade of carbon credits is ensuring the integrity of the process. There have been instances of fraud and abuse in the carbon credit market, which can undermine the credibility of the process.",
    },
    {
      title: "How do carbon credits help in mitigating climate change?",
      content:
        "Carbon credits provide an incentive for companies and organizations to reduce their greenhouse gas emissions. By creating a market-based mechanism to reduce greenhouse gas emissions, carbon credits help in mitigating climate change.",
    },
  ];

  return (
    <div className="accordion-list">
      {accordionData.map((item, index) => (
        <Accordion key={index} title={item.title} content={item.content} />
      ))}
    </div>
  );
};

export default AccordionList;
