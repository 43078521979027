import React from 'react'
import './image.css'

import {GoogleMap, withScriptjs, withGoogleMap, Marker} from 'react-google-maps'



function Map(){
    return( 
        <GoogleMap defaultZoom={4.5} defaultCenter={{lat: 27.023804, lng: 74.217934}} >
            
             <Marker position={{lat:22.5769414, lng:88.4279648}}/>
             <Marker position={{lat:26.171199, lng:91.761690}}/>
             <Marker position={{lat:19.0785451, lng:72.878176}}/>
             <Marker position={{lat:25.0742823, lng:55.1885387}}/>
             <Marker position={{lat:28.705320, lng:77.325500}}/>
        </GoogleMap >
    )
}

const WrappedMap = withScriptjs(withGoogleMap(Map))

const Maps = () => {
  return (
    <>
    <section>
        <h2 style={{
            textAlign:'center',
            marginBottom: "1rem"
            
        }}
        >Our Presence</h2>
        <div className="container maps__container" style={{
            width:"85vw", height:"100vh"
        }}>
        <WrappedMap
         googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAvDYtqDg67QPZDZ0l8f2A73YFlqlVcc0E`}
        loadingElement={<div style={{height: "80%"}} />}
        containerElement={<div style={{height: "80%"}} />}
        mapElement={<div style={{height: "100%"}} />}
        />
        </div>
    </section>
    </>
    )
}

export default Maps