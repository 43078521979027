import React from 'react'
import StoreHeader from './components/StoreHeader'

const Store = () => {
  return (
<>
<section>
  <StoreHeader/>
</section>
</>
    )
}

export default Store