import Image from '../images/solar-system-op.jpg'
import SectionHead from './SectionHead'
import {FaThumbsUp} from 'react-icons/fa'
import { values } from '../data'
import Card from '../UI/Card'   
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import Brochure from '../../src/Brochure.pdf'

import Aos from "aos";
import "aos/dist/aos.css"


const Values = () => {
    useEffect(() => {
        Aos.init({duration: 1500});
    }, []);
  return (
<section className='values'>
    <div className="container values__container">
        <div className="values__left">
            <div className="values__image">
                <img src={Image} alt="Solar" />
            </div>
        </div>
        <div className="values__right">
            <SectionHead icon={<FaThumbsUp/>} title="ADVANTAGES OF SOLAR"/> 
            <p>Green Power Solutions identifies the diverse benefits the solar energy has to offer and brings to you a few of its prominent perks:</p>
            <div className="values__wrapper" data-aos="fade-up">
                {
                    values.map(({id, icon, title, desc}) => {
                        return <Card key={id} className="values__value">
                            <span>{icon}</span>
                            <h4>{title}</h4>
                            <small>{desc}</small>
                        </Card>
                    })
                }
                
            </div>
            <div className='btn-adv' >
       <span> <Link to="/about" className='btn'>Learn More</Link> </span>
       <span> <a href={Brochure} download className='btn'>Download Brochure</a> </span>

            </div>
        </div>
    </div>

</section> 
 )
}

export default Values