import React from "react";
import "./contact.css";
import { GrMail } from "react-icons/gr";
import { FaFacebookMessenger } from "react-icons/fa";
import { ImWhatsapp } from "react-icons/im";
import { useRef as UseRef } from "react";
import emailjs from "@emailjs/browser";
import notebook from "../../images/notebook.gif";

const contact = () => {
  let temp = "string";
  const form = UseRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_kb3nwsh",
      "template_mw184se",
      form.current,
      "J3xl23J04fygK8HTY"
    );
    e.target.reset();
  };
  return (
    <section id="contact">
      <div className="contact__heading">
        <h4>
          It takes hours and days for us to analyze and generate solar solutions
          for you, but less than a minute for you to fill up this form
        </h4>
      </div>
      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <GrMail className="contact__option-icon" />
            <h4>Email</h4>
            <h5>info@greenpowersol.com</h5>
            <h5>hemant@greenpowersol.com</h5>
            <a href="mailto:info@greenpowersol.global" target="_blank">
              Send a Message
            </a>
          </article>
          {/* <article className="contact__option">
            <FaFacebookMessenger className="contact__option-icon" />
            <h4>Messenger</h4>
            <h5>profile username</h5>
            <a href="https://m.me/facebook_profile" target="_blank">
              Send a Message
            </a>
          </article> */}
          <article className="contact__option">
            <ImWhatsapp className="contact__option-icon" />
            <h4>WhatsApp</h4>
            <h5>+91 9903846237</h5>
            <a
              href="https://api.whatsapp.com/send?phone=919903846237"
              target="_blank"
            >
              Send a Message
            </a>
          </article>
        </div>
        {/* end of contact options */}
        <form ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            name="name"
            placeholder="Your Full Name"
            required
          />
          <input type="email" name="email" placeholder="Your E-Mail" required />
          <input
            type="phone"
            name="phone"
            placeholder="Your Phone No."
            required
          />
          <textarea
            name="message"
            rows="7"
            placeholder="Your Message"
            required
          ></textarea>
          <button type="submit" className="btn btn-primary">
            Send Message
          </button>
          <img src={notebook} alt="" className="note" />
        </form>
      </div>
    </section>
  );
};

export default contact;
