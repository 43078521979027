import React from "react";
import HeaderImage from "../../images/solar-header-op.jpg";
import Header from "../../components/Header";
import CarbonAudit from "../../components/Steps";
import { audit } from "../../data";
import Card from "../../UI/Card";
import "../about/about.css";
import AccordionList from "../../components/AccordianList";
import { Link } from "react-router-dom";

const Auditing = () => {
  return (
    <>
      <Header title="Carbon Auditing" image={HeaderImage}>
        Are you concerned about the impact of your activities on the
        environment? Do you want to reduce your carbon footprint but don't know
        where to start? Carbon auditing can help you measure your greenhouse gas
        emissions and identify areas where you can reduce them. In this article,
        we will explain what carbon auditing is, why it is important, and how it
        can benefit you and the planet. We will also provide you with some tips
        on how to conduct a carbon audit for your business or household
      </Header>
      <section>
        <div className="updated__vision-container">
          <h4>
            What is <span>Carbon Audting ? </span>
          </h4>
          <p>
            Carbon auditing is a process of measuring, quantifying, and
            analyzing the amount of greenhouse gases (GHGs) emitted by an
            individual, organization, or activity. It helps to identify the
            sources of emissions and areas where they can be reduced. Carbon
            auditing can be done for a specific project, a company, a city, or
            even a whole country. The results of a carbon audit can be used to
            set emission reduction targets, track progress towards them, and
            demonstrate the impact of mitigation measures.
          </p>
        </div>
        <div className="updated__vision-container">
          <h4>
            <span>Why</span> is Carbon Audting Important ?
          </h4>
          <p>
            Carbon auditing is important because GHG emissions are the main
            cause of climate change, which is one of the biggest challenges
            facing humanity today. By measuring and reducing emissions, we can
            slow down the pace of climate change, mitigate its impacts, and
            create a more sustainable future. Carbon auditing can also help
            businesses and households save money by identifying areas where
            energy efficiency can be improved and waste reduced. It can also
            help them comply with regulations and demonstrate their commitment
            to sustainability to stakeholders and customers.
          </p>
        </div>
        <div className="updated__vision-container">
          <h4>
            <span>How</span> to conduct a Carbon Audit ?
          </h4>
          <p
            style={{
              padding: "1rem",
            }}
          >
            Conducting a carbon audit involves several steps. Here are some
            general guidelines:
          </p>
          <CarbonAudit />
        </div>
        <div className="updated__vision-container">
          <h4>
            <span>Benefits</span> of Carbon Auditing
          </h4>
          <p>
            Carbon auditing has many benefits for individuals, businesses, and
            society as a whole. Here are some of them:
          </p>
          <div className="advantage__wrapper">
            {audit.map(({ id, icon, title, desc1, desc2, desc3, desc4 }) => {
              return (
                <Card key={id} className="choose__value">
                  <span>{icon}</span>
                  <h5
                    style={{
                      fontSize: "1rem",
                    }}
                    data-aos="zoom-in-down"
                  >
                    {title}
                  </h5>
                  <small>{desc1}</small>
                  <small>{desc2}</small>
                  <small>{desc3}</small>
                  <small>{desc4}</small>
                </Card>
              );
            })}
          </div>
        </div>
        <div className="updated__vision-container">
          <p>
            Carbon auditing is a powerful tool for measuring and reducing GHG
            emissions, promoting sustainability, and creating a better future
            for all. By conducting a carbon audit, you can gain insights into
            your environmental impact, identify opportunities for improvement,
            and take action to mitigate climate change. Whether you are a
            business owner, a homeowner, or a concerned citizen, carbon auditing
            can help you make a difference. So, why not start today?
          </p>
        </div>

        <div
          style={{
            marginBottom: "1rem",
          }}
          className="updated__vision-container"
        >
          <h4>
            <span>FAQs</span> About Carbon Audting
          </h4>
        </div>
        <div
          style={{
            marginRight: "6rem",
            marginLeft: "6rem",
          }}
        >
          <AccordionList />
        </div>
      </section>
      <div className="programs__button">
        <Link to="/contact" className=" btn ">
          Contact Us
        </Link>
      </div>
    </>
  );
};

export default Auditing;
