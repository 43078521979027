import Programs from "../../components/Programs";
import Header from "../../components/Header";
import HeaderImage from "../../images/solar-service.jpg";
import { Link } from "react-router-dom";
import "./plans.css";

const Plans = () => {
  return (
    <>
      <Header title="Services" image={HeaderImage}></Header>
      <Programs />
      <div className="programs__button">
        <Link to="/contact" className=" btn ">
          Contact Us
        </Link>
      </div>
    </>
  );
};

export default Plans;
