import React from 'react'
import './solution.css'
import HeaderImage from '../../images/header33.jpg'
import Header from '../../components/Header'
import { solution } from '../../data'
import Card from '../../UI/Card'
import { Link } from 'react-router-dom'
const Solution = () => {
  return (
    <>
   <Header title="Solar PV Solutions for Electricity Generation" image={HeaderImage}>
    </Header>
    <div className="container solution__container">
      <h3 > 
      Generation of Clean and green Electricity with nil impact on the environment. We look forward to make life easy with technological advancement along with reducing the carbon footprint on the globe
      </h3>
      <div className="solution__wrapper" >
                {
                    solution.map(({id, icon, title, desc}) => {
                        return <Card key={id} className='value__solution' >
                            <span>{icon}</span>
                            <h4>{title}</h4>
                            
                            <small>{desc}</small>
                        </Card>
                    })
                }
                
            </div>
    </div>
    <div className='programs__button'>
    <Link to="/contact" className=' btn '>Contact Us</Link>

   
    </div>
    </>
  )
}

export default Solution