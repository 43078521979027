import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { links } from "../data";
import { FaBars } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import Applications from "../pages/applications/Applications";

import "./navbar.css";
import Logo from "../../src/images/logo.png";

import React from "react";

const Navbar = () => {
  const [isNavShowing, setIsNavShowing] = useState(false);

  const [value, setValue] = useState("");

  function handleClick(event) {
    setValue(event.target.value);
  }

  return (
    <nav className="nav">
      <div className="container nav__container">
        <Link to="/" className="logo" onClick={() => setIsNavShowing(false)}>
          <img src={Logo} alt="Logo" className="logo__img" />
        </Link>
        <ul
          className={`nav__links ${isNavShowing ? "show__nav" : "hide__nav"}`}
        >
          {links.map(({ name, path }, index) => {
            return (
              <li key={index}>
                <NavLink
                  to={path}
                  className={({ isActive }) => (isActive ? "active-nav" : "")}
                  onClick={() => setIsNavShowing((prev) => !prev)}
                >
                  {name}
                </NavLink>
              </li>
            );
          })}

          <li>
            <a href="http://store.greenpowersol.com" className="store">
              Online Store
            </a>
          </li>
        </ul>
        <button
          className="nav__toggle-btn"
          onClick={() => setIsNavShowing((prev) => !prev)}
        >
          {isNavShowing ? <MdOutlineClose /> : <FaBars />}
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
